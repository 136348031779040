@charset "UTF-8";
@font-face {
  font-family: "Lora";
  src: url("../fonts/Lora-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Définition de la police Ribeye */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat";
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100%;
  max-height: auto;
  height: 100vh;
  font-family: "Lora", Helvetica, sans-serif;
}

.root {
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.imgMidContainer {
  background-image: url("../../assets/images/worker1.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.verticalLine {
  display: inline-block;
  height: 130px;
  margin: 0 20px;
}

.mapStyle {
  height: 550px;
  width: 750px;
  border-radius: 150px;
}
@media (max-width: 1200px) {
  .mapStyle {
    height: 500px;
    width: 700px;
  }
}
@media (max-width: 992px) {
  .mapStyle {
    height: 450px;
    width: 650px;
  }
}
@media (max-width: 768px) {
  .mapStyle {
    height: 400px;
    width: 600px;
  }
}
@media (max-width: 576px) {
  .mapStyle {
    height: 350px;
    width: 500px;
  }
}

