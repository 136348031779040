@font-face {
    font-family: 'Lora';
    src: url('../fonts/Lora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Définition de la police Ribeye */
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat';

}
body {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100%;
    max-height: auto;
    height: 100vh;
    font-family: 'Lora', Helvetica, sans-serif;
}

.root {
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.imgMidContainer {
    background-image: url('../../assets/images/worker1.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .verticalLine{
    display: inline-block;
    height: 130px;
    margin: 0 20px;
  }
